import { logger } from './logger.util';

// Local Storage Functions
function setLocalItem<T>(key: string, value: T): boolean {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
        return true;
    } catch (error) {
        logger('error', 'Error saving to localStorage:', error);
        return false;
    }
}

function getLocalItem<T>(key: string): T | null {
    try {
        const item = localStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : null;
    } catch (error) {
        logger('error', 'Error reading from localStorage:', error);
        return null;
    }
}

function removeLocalItem(key: string): void {
    localStorage.removeItem(key);
}

function clearLocal(): void {
    localStorage.clear();
}

// Session Storage Functions
function setSessionItem<T>(key: string, value: T): boolean {
    try {
        const serializedValue = JSON.stringify(value);
        sessionStorage.setItem(key, serializedValue);
        return true;
    } catch (error) {
        logger('error', 'Error saving to sessionStorage:', error);
        return false;
    }
}

function getSessionItem<T>(key: string): T | null {
    try {
        const item = sessionStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : null;
    } catch (error) {
        logger('error', 'Error reading from sessionStorage:', error);
        return null;
    }
}

function removeSessionItem(key: string): void {
    sessionStorage.removeItem(key);
}

function clearSession(): void {
    sessionStorage.clear();
}

export {
    setLocalItem,
    getLocalItem,
    removeLocalItem,
    clearLocal,
    setSessionItem,
    getSessionItem,
    removeSessionItem,
    clearSession,
};
