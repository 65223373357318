import type { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';

function GlobalProvider({ children }: PropsWithChildren) {
    return (
        <SWRConfig
            value={{
                revalidateOnFocus: false,
                // You can add more SWR config options here
            }}
        >
            {children}
        </SWRConfig>
    );
}

export default GlobalProvider;
