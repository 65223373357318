import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout';
import MainLayout from '@/layouts/MainLayout';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout';
import { ROUTES } from './routes';

// NotFound
const NotFound = lazy(() => {
    return import('@/pages/NotFound');
});

// Auth
const Login = lazy(() => {
    return import('@/pages/Auth/Login');
});
const LoginByQR = lazy(() => {
    return import('@/pages/Auth/LoginByQR');
});
const SuccessfulPair = lazy(() => {
    return import('@/pages/Auth/SuccessfulPair');
});

// Home
const Home = lazy(() => {
    return import('@/pages/Home');
});

// Play
const Play = lazy(() => {
    return import('@/pages/Play');
});

// Movies
const Movies = lazy(() => {
    return import('@/pages/Medias/Movies');
});
const MovieDetail = lazy(() => {
    return import('@/pages/Medias/Movies/MovieDetail');
});

// Series
const Series = lazy(() => {
    return import('@/pages/Medias/Series');
});
const SeriesDetail = lazy(() => {
    return import('@/pages/Medias/Series/SeriesDetail');
});

// Search
const Search = lazy(() => {
    return import('@/pages/Medias/Search');
});

export const Router = () => {
    return (
        <Switch>
            {/* Public Routes */}
            <Route path={ROUTES.AUTH.LOGIN} exact>
                <UnauthorizedLayout>
                    <Login />
                </UnauthorizedLayout>
            </Route>
            <Route path={ROUTES.AUTH.LOGIN_WITH_QR} exact>
                <UnauthorizedLayout>
                    <LoginByQR />
                </UnauthorizedLayout>
            </Route>
            <Route path={ROUTES.AUTH.PAIR} exact>
                <UnauthorizedLayout>
                    <Login />
                </UnauthorizedLayout>
            </Route>
            <Route path={ROUTES.AUTH.SUCCESSFUL_PAIR} exact>
                <UnauthorizedLayout>
                    <SuccessfulPair />
                </UnauthorizedLayout>
            </Route>

            {/* Play Route - Outside MainLayout */}
            <Route path={ROUTES.MEDIAS.PLAY} exact>
                <Play />
            </Route>

            {/* Protected Routes */}
            <Route path="/">
                <MainLayout>
                    <Switch>
                        <Route path={ROUTES.HOME} exact>
                            <AuthenticatedLayout>
                                <Home />
                            </AuthenticatedLayout>
                        </Route>

                        {/* Media Routes */}
                        <Route path={ROUTES.MEDIAS.MOVIES} exact>
                            <AuthenticatedLayout>
                                <Movies />
                            </AuthenticatedLayout>
                        </Route>
                        <Route path={ROUTES.MEDIAS.MOVIE_DETAIL} exact>
                            <AuthenticatedLayout>
                                <MovieDetail />
                            </AuthenticatedLayout>
                        </Route>
                        <Route path={ROUTES.MEDIAS.SERIES} exact>
                            <AuthenticatedLayout>
                                <Series />
                            </AuthenticatedLayout>
                        </Route>
                        <Route path={ROUTES.MEDIAS.SERIES_DETAIL} exact>
                            <AuthenticatedLayout>
                                <SeriesDetail />
                            </AuthenticatedLayout>
                        </Route>
                        <Route path={ROUTES.MEDIAS.SEARCH} exact>
                            <AuthenticatedLayout>
                                <Search />
                            </AuthenticatedLayout>
                        </Route>

                        {/* 404 Route */}
                        <Route path="*" component={NotFound} />
                    </Switch>
                </MainLayout>
            </Route>
        </Switch>
    );
};
