import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Router as AppRouter } from './Router';

function ReactRouterProvider() {
    return (
        <Router>
            <Suspense fallback="">
                <AppRouter />
            </Suspense>
        </Router>
    );
}

export default ReactRouterProvider;
