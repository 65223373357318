import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
// Providers
import Providers from './providers';
// StyleSheets
import '@/styles/global.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.querySelector('#root')!).render(
    <StrictMode>
        <Providers />
    </StrictMode>,
);
