import { useEffect, useState } from 'react';

const useDetectTV = (): boolean => {
    const [isTV, setIsTV] = useState<boolean>(false);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        // Common TV platform identifiers
        const tvPlatforms = [
            'tv',
            'smart-tv',
            'smarttv',
            'googletv',
            'appletv',
            'tizen',
            'webos',
            'roku',
            'tvos',
            'vidaa',
        ];

        // Check if any TV platform identifier exists in user agent
        const isTVDevice = tvPlatforms.some((platform) =>
            userAgent.includes(platform),
        );

        setIsTV(isTVDevice);
    }, []);

    return isTV;
};

export default useDetectTV;
