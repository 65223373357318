import GlobalProvider from './GlobalProvider';
import { PlayProvider } from './PlayProvider';
import RouterProvider from './RouterProvider';

function Providers() {
    return (
        <GlobalProvider>
            <PlayProvider>
                <RouterProvider />
            </PlayProvider>
        </GlobalProvider>
    );
}

export default Providers;
