import { useEffect } from 'react';
import Header from '@/components/ui/Header';
import { useLoginRedirect } from '@/hooks/useLoginRedirect';
import { getLocalItem } from '@/utils/localStorage.util';
import styles from './AuthenticatedLayout.module.css';

function AuthenticatedLayout({ children }: { children?: React.ReactNode }) {
    const loginRedirect = useLoginRedirect();
    useEffect(() => {
        const isAuthenticated = !!getLocalItem<string>('accessToken');
        if (!isAuthenticated) {
            loginRedirect(true);
        }
    }, [loginRedirect]);

    return (
        <main>
            <Header />
            <div className={styles.container}>{children}</div>
        </main>
    );
}

export default AuthenticatedLayout;
