import { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { PlayContext } from '@/contexts/Play.context';
import type {
    MovieProps,
    PlayProps,
    SeriesProps,
} from '@/contexts/Play.context';

interface PlayProviderProps {
    children: ReactNode;
    initialData?: PlayProps;
}

export function PlayProvider({ children, initialData }: PlayProviderProps) {
    const [playData, setPlayData] = useState<PlayProps>(
        initialData || {
            title: '',
            link: '',
            type: 'MOVIE',
            cover: '',
        },
    );

    const updatePlayData = (data: Partial<PlayProps>) => {
        setPlayData((previous) => {
            if (data.type && data.type !== previous.type) {
                return data.type === 'MOVIE'
                    ? ({
                          type: 'MOVIE',
                          cover: data.cover || previous.cover,
                          title: 'title' in data ? data.title || '' : '',
                          link: 'link' in data ? data.link || '' : '',
                          skip: data.skip,
                      } as MovieProps)
                    : ({
                          type: 'SERIES',
                          cover: data.cover || previous.cover,
                          playList:
                              'playList' in data ? data.playList || [] : [],
                          activeEpisode:
                              'activeEpisode' in data
                                  ? data.activeEpisode || 0
                                  : 0,
                          skip: data.skip,
                      } as SeriesProps);
            }

            return {
                ...previous,
                ...data,
            } as PlayProps;
        });
    };

    const providerValue = useMemo(() => {
        return {
            playData,
            updatePlayData,
            isMovie: (data: PlayProps): data is MovieProps =>
                data.type === 'MOVIE',
            isSeries: (data: PlayProps): data is SeriesProps =>
                data.type === 'SERIES',
        };
    }, [playData]);

    return (
        <PlayContext.Provider value={providerValue}>
            {children}
        </PlayContext.Provider>
    );
}
