import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@/providers/RouterProvider/routes';
import useDetectTv from './useDetectTv';

export function useLoginRedirect() {
    const history = useHistory();
    const isTV = useDetectTv();

    return useCallback(
        (shouldReplace = false) => {
            const redirectPath = isTV
                ? ROUTES.AUTH.LOGIN_WITH_QR
                : ROUTES.AUTH.LOGIN;
            const navigate = shouldReplace ? history.replace : history.push;
            navigate(redirectPath);
        },
        [isTV, history],
    );
}
