import type { CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import { getLocalItem } from '@/utils/localStorage.util';

// import { createResponseErrorInterceptor } from './interceptors/error.interceptor';

function getUserToken() {
    const token = getLocalItem<string>('accessToken');
    if (token) {
        return `Bearer ${token}`;
    }
    return null;
}

interface CreateAxiosArguments {
    baseURL: string;
    timeout: number;
}

export function createAxios({ baseURL, timeout }: CreateAxiosArguments) {
    const axiosConfig: CreateAxiosDefaults = {
        baseURL,
        timeout,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const axiosInstance = axios.create(axiosConfig);

    axiosInstance.interceptors.request.use((request) => {
        const token = getUserToken();
        if (token) {
            request.headers.Authorization = token;
        }
        return request;
    });

    // axiosInstance.interceptors.response.use(
    //     undefined,
    //     createResponseErrorInterceptor({}),
    // );

    return axiosInstance;
}
