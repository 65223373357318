export const ROUTES = {
    HOME: '/',
    AUTH: {
        LOGIN: '/auth/login',
        LOGIN_WITH_QR: '/auth/login/qr',
        PAIR: '/auth/pair/:deviceCode',
        SUCCESSFUL_PAIR: '/auth/successful-pair',
    },
    MEDIAS: {
        PLAY: '/play',
        MOVIES: '/medias/movies',
        MOVIE_DETAIL: '/medias/movies/:id',
        SERIES: '/medias/series',
        SERIES_DETAIL: '/medias/series/:id',
        SEARCH: '/medias/search',
    },
} as const;
