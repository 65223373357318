import { z } from 'zod';

const environmentsSchema = z.object({
    API_BASE_URL: z.coerce.string().url(),
    API_TIMEOUT: z.coerce.number().optional(),
    isDevelopment: z.coerce.boolean(),
});

const unknownEnvironments: Record<string, unknown> = {
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
    API_TIMEOUT: import.meta.env.VITE_API_TIMEOUT,
    isDevelopment: import.meta.env.DEV,
};

const safeParseEnvironments = environmentsSchema.safeParse(unknownEnvironments);

if (!safeParseEnvironments.success) {
    throw safeParseEnvironments.error;
}

export const environments = safeParseEnvironments.data;
