import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@/providers/RouterProvider/routes';
import { getLocalItem } from '@/utils/localStorage.util';

function UnauthorizedLayout({ children }: { children?: React.ReactNode }) {
    const history = useHistory();

    useEffect(() => {
        const isAuthenticated = !!getLocalItem<string>('accessToken');
        if (isAuthenticated) {
            history.replace(ROUTES.HOME);
        }
    }, [history]);

    return children;
}

export default UnauthorizedLayout;
